<template>
  <v-container>
    <v-row
      align="start"
      justify="end"
    >
      <h1><div class="subtitle navy-text"><strong>MY PROFILE</strong><br/></div></h1>
    </v-row>
    <v-row
      align="start"
      justify="end"
    >
      <div class="base-text">ข้อมูลของฉัน</div>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>

    <!-- <div class="text-xs-right">
      <h1><div class="subtitle navy-text"><strong>MY PROFILE</strong><br/></div></h1>
      <div class="caption base-text">ข้อมูลของฉัน</div>
    </div> -->
    <br/>
    <!-- <div class="caption">
      <v-layout>
        <v-dialog v-model="dialog" max-width="450">
          <template v-slot:activator="{ on }">
            <div class="grey-text text-xs-right"><i>&nbsp;สิทธิพิเศษก่อนใคร
              กรอกข้อมูลเพื่อสมัคร &nbsp;</i>
              <span class="navy-text"> <a v-on="on"><u><i>Achieve+ Member</i></u></a></span>
            </div>
          </template>
          <v-card>
            <v-card-title class="subtitle text-xs-center">ACHIEVE+ MEMBER POLICY</v-card-title>
              <v-card-text class="font-dialog grey-text">
                พิเศษเฉพาะผู้เรียนเท่านั้น ! กรอกข้อมูล Achieve+ Member เพื่อรับสิทธิประโยชน์
                จาก Achieve Plus ก่อนใคร<br/><br/>
                อาทิ<br/>
                - ส่วนลดชุดวิชา 25% ภายในปี 2562 นี้<br/>
                - สิทธิในการเข้าร่วมกิจกรรมสุดพิเศษที่ทาง Achieve จัดขึ้น<br/>
                - ส่วนลดการเข้าร่วม Workshop เพื่อเสริมสร้างทักษะและการนำไปประยุกต์ใช้
                เพื่อให้คุณ Achieve ตามเป้าหมายที่วางไว้<br/>
                - เพิ่มโอกาสความก้าวหน้าในสายอาชีพที่ต้องการ ผ่านการทำงานร่วมกับบริษัทชั้นนำ<br/>
                - สิทธิประโยชน์อื่น ๆ อีกมากมาย<br/><br/>
                ***ต้องเป็นผู้ที่เคยเรียนอย่างน้อย 1 Pathway หรือกำลังเรียนอยู่เท่านั้น***<br/>
              </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="dialog = false">CLOSE</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </div> -->
    <v-form
      class="caption"
      ref="form"
      v-model="valid"
      lazy-validation
      >
      <v-container>
        <v-row wrap>
          <div class="subtitle navy-text"><strong>ข้อมูลการเข้าสู่ระบบ</strong></div>
        </v-row>
        <v-row>
          <v-col cols="auto" sm="6" md="6">
            <v-text-field
              filled
              :label="loginMethod.toUpperCase()"
              v-model="displayEmail"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="auto" sm="6" md="6">
            <ResetPassword
            v-if="loginMethod === 'email'"
              text="Reset Password"
              :forgotemail="displayEmail"
            />
          </v-col>
        </v-row>
        <v-row mb-5>
          <v-col cols="auto" sm="12" md="12" v-if="loginMethod !== 'facebook'">
            <v-row v-if="!fbLinked">
              <v-btn
                color="#314992"
                @click="linkFacebook" dark
              >
                LINK FACEBOOK
              </v-btn>
              <span v-if="msg" class="red--text ml-3">Required</span>
            </v-row>
            <v-row v-else class="d-flex align-center">
              <v-col cols="2" sm="2" md="1">
                <v-icon color="#314992" large>fab fa-facebook-square</v-icon>
              </v-col>
              <v-col cols="10" sm="10" md="11">Facebook Linked with {{ fbEmail }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" sm="12" md="12" v-if="loginMethod !== 'google'">
            <v-row v-if="!ggLinked">
              <v-btn
                color="error"
                @click="linkGoogle" dark
              >
                LINK GOOGLE
              </v-btn>
              <!-- <span v-if="msg" class="red--text ml-3">Required</span> -->
            </v-row>
            <v-row v-else class="d-flex align-center">
              <v-col cols="2" sm="2" md="1">
                <v-icon color="#DD4B39" large>fab fab fa-google</v-icon>
              </v-col>
              <v-col cols="10" sm="10" md="11">Google Linked with {{ ggEmail }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" sm="12" md="12" v-if="loginMethod !== 'microsoft'">
            <v-row v-if="!msLinked">
              <v-btn
                color="#00A4EF"
                @click="linkMicrosoft" dark
              >
                LINK MICROSOFT
              </v-btn>
              <!-- <span v-if="msg" class="red--text ml-3">Required</span> -->
            </v-row>
            <v-row v-else class="d-flex align-center">
              <v-col cols="2" sm="2" md="1">
                <v-icon color="#00A4EF" large>fab fa-microsoft</v-icon>
              </v-col>
              <v-col cols="10" sm="10" md="11">Microsoft Linked with {{ msEmail }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" sm="12" md="12">
            <v-row v-if="!cumaEmail" class="d-flex align-center">
              <LoginCUMA />
            </v-row>
            <v-row v-else>
              <v-col cols="2" sm="2" md="1" align-self="center" class="pa-0">
                <v-img max-height="50" max-width="50"
                  src="../../assets/image/achieve-logo-circle.png">
                </v-img>
              </v-col>
              <v-col cols="10" sm="10" md="11">CUMA Linked with {{ cumaEmail }}</v-col>
            </v-row>
          </v-col>
          <!-- <v-flex xs12 sm12 md12 d-flex>
            <div v-if="!emailLinked">
              <v-btn
                @click="linkEmail"
              >
                LINK EMAIL
              </v-btn>
            </div>
            <div v-else class="d-flex align-center">
              <v-flex d-flex xs2 sm2 md1>
                <v-icon large>fa fa-envelope</v-icon>
              </v-flex>
              <v-flex d-flex xs10 sm10 md11>Email Linked with {{ email }}</v-flex>
            </div>
          </v-flex> -->
        </v-row>
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <v-row class="subtitle navy-text"><strong>ข้อมูลพื้นฐาน</strong></v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row
              justify="end"
            >
              <v-btn color="primary"
              fab small dark
              @click="isEditing = !isEditing">
              <v-icon v-if="isEditing">close</v-icon>
              <v-icon v-else>edit</v-icon>
            </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="fnameth"
              label="ชื่อ"
              :disabled="!notEdit('fth')"
              required
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="lnameth"
              label="นามสกุล"
              :disabled="!notEdit('lth')"
              required
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="fnameen"
              label="FIRSTNAME"
              :disabled="!notEdit('fen')"
              required
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="lnameen"
              label="LASTNAME"
              :disabled="!notEdit('len')"
              required
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <div class="subtitle navy-text">
            <strong>ข้อมูลส่วนตัว</strong><span class="caption"> (หากไม่มีใส่ -)</span>
          </div>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="วันเกิด"
                  :disabled="!isEditing"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  :rules="required"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-select
              v-model="selectSex"
              :items="sex"
              :disabled="!isEditing"
              required
              :rules="required"
              label="เพศ"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-autocomplete
              v-model="selectOccupation"
              :loading="loading"
              :items="occupation"
              :search-input.sync="searchOccupation"
              :disabled="!isEditing"
              cache-items
              hide-no-data
              hide-details
              label="อาชีพ"
              required
              :rules="required"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="position"
              label="ตำแหน่งงาน"
              :disabled="!isEditing"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-select
              v-model="workAge"
              :items="agesofwork"
              :disabled="!isEditing"
              label="อายุงาน (ปี)"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-autocomplete
              v-model="selectDegree"
              :loading="loading"
              :items="degree"
              :search-input.sync="searchDegree"
              :disabled="!isEditing"
              cache-items
              hide-no-data
              hide-details
              label="ระดับการศึกษา"
              required
              :rules="required"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-select
              v-model="selectSalary"
              :items="salary"
              :disabled="!isEditing"
              label="เงินเดือน (บาท)"
              required
              :rules="required"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-text-field
              v-model="tel"
              label="เบอร์โทรศัพท์"
              :disabled="!isEditing"
              :rules="required"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-text-field
              v-model="lineId"
              label="Line ID"
              :disabled="!isEditing"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex">
            <v-text-field
              v-model="contactEmail"
              label="Contact Email"
              :rules="emailRules"
              :disabled="!isEditing"
            ></v-text-field>
          </v-col>

        </v-row><br/>

        <v-row>
          <div class="subtitle navy-text">
            <strong>ที่อยู่ปัจจุบัน</strong> <span class="caption"> (หากไม่มีใส่ - )</span>
          </div>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="address"
              label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
              :disabled="!isEditing"
              :rules="required"
              required
              outlined
              height="100px"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <label class="caption">ค้นหาอัตโนมัติ </label><v-icon>search</v-icon>
            <thai-address-input
              class="v-text-field"
              :class="isEditing ? 'border-bottom' : 'border-bottom-disabled'"
              type="search"
              v-model="searchAddress"
              input-class="v-input"
              @selected="onSelected"
              :disabled="!isEditing"
              placeholder="โปรดระบุตำบล / อำเภอ / จังหวัด / รหัสไปรษณีย์"
            ></thai-address-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="ตำบล / แขวง"
              v-model="subdistrict"
              disabled
              :rules="required"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="อำเภอ / เขต"
              v-model="district"
              disabled
              :rules="required"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              type="province"
              label="จังหวัด"
              v-model="province"
              disabled
              @selected="onSelected"
              :rules="required"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="รหัสไปรษณีย์"
              v-model="postalCode"
              disabled
              :rules="required"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="mt-48px text-xs-center">
          <v-col cols="12">
            <v-row
              justify="center"
            >
              <v-btn
                :disabled="!isEditing"
                color="error"
                @click="saveEdit"
                dark
              >SAVE</v-btn>
            </v-row>
          </v-col>
        </div>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import ResetPassword from '@/components/ResetPassword.vue';
import LoginCUMA from '../../components/LoginCUMA.vue';

const { errorMsg } = require('../../static/errorMessage');

export default {
  name: 'profile',
  components: {
    ResetPassword,
    LoginCUMA,
  },
  props: ['msg'],
  data: () => ({
    valid: false,
    displayEmail: '',
    fnameth: '',
    lnameth: '',
    fnameen: '',
    lnameen: '',
    date: '',
    menu: false,
    searchOccupation: '',
    searchDegree: '',
    selectOccupation: '',
    selectDegree: '',
    position: '',
    sex: ['ชาย', 'หญิง', 'ไม่ระบุ'],
    selectSex: '',
    agesofwork: ['0-1', '2-3', '4-5', '6-10', 'มากกว่า 10 ปี'],
    workAge: '',
    occupation: ['ข้าราชการ', 'พนักงานเอกชน', 'นักธุรกิจ', 'แพทย์/เภสัชกร', 'วิศวกร', 'นักกฎหมาย', 'นักบัญชี/การตลาด', 'ครู/อาจารย์', 'นักเรียน/นักศึกษา', 'อื่น ๆ'],
    degree: ['ปริญญาเอก', 'ปริญญาโท', 'ปริญญาตรี', 'อนุปริญญา', 'ปวช.', 'ปวส.', 'มัธยมศึกษาตอนปลาย', 'มัธยมศึกษาตอนต้น', 'ต่ำกว่ามัธยมศึกษา', 'ไม่ระบุ'],
    salary: ['ต่ำกว่า 15,000 บาท', '15,001 - 30,000 บาท', '30,001 - 45,000 บาท', '45,001 บาทขึ้นไป'],
    selectSalary: '',
    tel: '',
    lineId: '',
    contactEmail: '',
    isEditing: false,
    loading: false,
    dialog: false,
    address: '',
    subdistrict: '',
    district: '',
    province: '',
    postalCode: '',
    searchAddress: '',
    required: [value => !!value || 'Required.'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    email: '',
    emailLinked: false,
    fbLinked: false,
    fbEmail: '',
    ggLinked: false,
    ggEmail: '',
    msLinked: false,
    msEmail: '',
    loginMethod: '',
    cumaEmail: '',
    cumaPopup: false,
    isMember: false,
  }),
  mounted() {
    if (this.msg === 'apply') {
      this.required = [value => !!value || 'Required.'];
      this.isEditing = true;
      this.$refs.form.validate();
    }
  },
  watch: {
    menu(val) {
      return val && setTimeout(() => { this.$refs.picker.activePicker = 'YEAR'; });
    },
    profile(val) {
      this.fnameth = val && val.fth ? val.fth : null;
      this.lnameth = val && val.lth ? val.lth : null;
      this.fnameen = val && val.fen ? val.fen : null;
      this.lnameen = val && val.len ? val.len : null;
      this.date = val && val.dob ? val.dob : null;
      this.selectSex = val && val.sex ? val.sex : null;
      this.selectOccupation = val && val.occupation ? val.occupation : null;
      this.position = val && val.position ? val.position : null;
      this.selectDegree = val && val.degree ? val.degree : null;
      this.workAge = val && val.workAge ? val.workAge : null;
      this.selectSalary = val && val.salary ? val.salary : null;
      this.tel = val && val.tel ? val.tel : null;
      this.lineId = val && val.lineId ? val.lineId : null;
      this.contactEmail = val && val.contactEmail ? val.contactEmail : null;
      this.address = val && val.address ? val.address : null;
      this.subdistrict = val && val.subdistrict ? val.subdistrict : null;
      this.district = val && val.district ? val.district : null;
      this.province = val && val.province ? val.province : null;
      this.postalCode = val && val.postalCode ? val.postalCode : null;
      this.fbLinked = val && val.facebookEmail;
      this.fbEmail = val && val.facebookEmail ? val.facebookEmail : '';
      this.ggLinked = val && val.googleEmail;
      this.ggEmail = val && val.googleEmail ? val.googleEmail : '';
      this.msLinked = val && val.microsoftEmail;
      this.msEmail = val && val.microsoftEmail ? val.microsoftEmail : '';
      this.emailLinked = val && val.email;
      this.email = val && val.email ? val.email : '';
      if (this.loginMethod === 'email') {
        this.displayEmail = this.email;
      } else if (this.loginMethod === 'google') {
        this.displayEmail = this.ggEmail;
      } else if (this.loginMethod === 'facebook') {
        this.displayEmail = this.fbEmail;
      } else if (this.loginMethod === 'microsoft') {
        this.displayEmail = this.msEmail;
      }
      this.cumaEmail = val && val.cumaEmail ? val.cumaEmail : '';
      this.isMember = val && val.isMember;
    },
    // userEmail(val) {
    //   console.log('main', { ...val });
    //   this.email = val.email;
    // },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    // userEmail() {
    //   console.log('main', { ...val });
    //   return this.$store.getters.user;
    // },
  },
  methods: {
    saveEdit() {
      if (this.isMember) {
        if (this.$refs.form.validate()) {
          this.isEditing = !this.isEditing;
          this.saveProfile();
        } else {
          this.$store.dispatch('setError', errorMsg['req/text-field']);
        }
      } else {
        this.isEditing = !this.isEditing;
        this.saveProfile();
      }
    },
    onSelected(address) {
      this.subdistrict = address.subdistrict;
      this.district = address.district;
      this.province = address.province;
      this.postalCode = address.postalCode;
    },
    linkFacebook() {
      this.saveProfile();
      this.$store.dispatch('facebookLink')
        .then(() => {
          this.$router.push('/profile/index');
        });
    },
    linkGoogle() {
      this.saveProfile();
      this.$store.dispatch('googleLink')
        .then(() => {
          this.$router.push('/profile/index');
        });
    },
    linkMicrosoft() {
      this.saveProfile();
      this.$store.dispatch('microsoftLink')
        .then(() => {
          this.$router.push('/profile/index');
        });
    },
    // linkEmail() {
    //   this.$store.dispatch('setLoading', true);
    //   this.saveProfile();
    //   this.$store.dispatch('emailLink', { email: '', password: '' })
    //     .then(() => this.$store.dispatch('setLoading', false));
    // },
    saveProfile() {
      const { uid } = this.$store.getters.user;
      const profile = {
        fth: this.fnameth ? this.fnameth : null,
        lth: this.lnameth ? this.lnameth : null,
        fen: this.fnameen ? this.fnameen : null,
        len: this.lnameen ? this.lnameen : null,
        dob: this.date ? this.date : null,
        sex: this.selectSex ? this.selectSex : null,
        occupation: this.selectOccupation ? this.selectOccupation : null,
        position: this.position ? this.position : null,
        degree: this.selectDegree ? this.selectDegree : null,
        workAge: this.workAge ? this.workAge : null,
        salary: this.selectSalary ? this.selectSalary : null,
        tel: this.tel ? this.tel : null,
        lineId: this.lineId ? this.lineId : null,
        contactEmail: this.contactEmail ? this.contactEmail : null,
        address: this.address ? this.address : null,
        subdistrict: this.subdistrict ? this.subdistrict : null,
        district: this.district ? this.district : null,
        province: this.province ? this.province : null,
        postalCode: this.postalCode ? this.postalCode : null,
      };
      this.$store.dispatch('setProfile', { uid, data: profile })
        .then(() => {
          this.$store.dispatch('getProfile', { uid });
        });
      if (this.msg === 'apply') {
        this.$router.push('/profile/member');
      }
    },
    notEdit(val) {
      return this.isEditing && this.profile && !this.profile[val];
    },
  },
  created() {
    const { profile } = this.$store.getters;
    this.fnameth = profile && profile.fth ? profile.fth : null;
    this.lnameth = profile && profile.lth ? profile.lth : null;
    this.fnameen = profile && profile.fen ? profile.fen : null;
    this.lnameen = profile && profile.len ? profile.len : null;
    this.date = profile && profile.dob ? profile.dob : null;
    this.selectSex = profile && profile.sex ? profile.sex : null;
    this.selectOccupation = profile && profile.occupation ? profile.occupation : null;
    this.position = profile && profile.position ? profile.position : null;
    this.selectDegree = profile && profile.degree ? profile.degree : null;
    this.workAge = profile && profile.workAge ? profile.workAge : null;
    this.selectSalary = profile && profile.salary ? profile.salary : null;
    this.tel = profile && profile.tel ? profile.tel : null;
    this.lineId = profile && profile.lineId ? profile.lineId : null;
    this.contactEmail = profile && profile.contactEmail ? profile.contactEmail : null;
    this.address = profile && profile.address ? profile.address : null;
    this.subdistrict = profile && profile.subdistrict ? profile.subdistrict : null;
    this.district = profile && profile.district ? profile.district : null;
    this.province = profile && profile.province ? profile.province : null;
    this.postalCode = profile && profile.postalCode ? profile.postalCode : null;
    this.fbLinked = profile && profile.facebookEmail;
    this.fbEmail = profile && profile.facebookEmail ? profile.facebookEmail : '';
    this.ggLinked = profile && profile.googleEmail;
    this.ggEmail = profile && profile.googleEmail ? profile.googleEmail : '';
    this.msLinked = profile && profile.microsoftEmail;
    this.msEmail = profile && profile.microsoftEmail ? profile.microsoftEmail : '';
    this.emailLinked = profile && profile.email;
    this.email = profile && profile.email ? profile.email : '';
    const method = this.$store.getters.loginMethod;
    this.loginMethod = method;
    this.isMember = profile && profile.isMember ? profile.isMember : false;
    if (method === 'email') {
      this.displayEmail = this.email;
    } else if (method === 'google') {
      this.displayEmail = this.ggEmail;
    } else if (method === 'facebook') {
      this.displayEmail = this.fbEmail;
    } else if (method === 'microsoft') {
      this.displayEmail = this.msEmail;
    }
    this.cumaEmail = profile && profile.cumaEmail ? profile.cumaEmail : '';
  },
};
</script>

<style scoped>
.v-messages__message{
 font-size: 1rem;
}
.v-input{
  font-size: 0.5rem;
}
.error--text{
  font-size: 0.5rem;
}
.font-dialog{
  font-family: 'Prompt';
  font-size: 0.80rem;
  font-weight: 400;
  line-height: 18px;
}
.v-btn__content{
  color:black;
}
.border-bottom{
  border-bottom-style: solid !important;
  border-bottom-width: 0.5px !important;
  border-bottom-color: black !important;
}
.border-bottom-disabled{
  border-bottom-style: dashed !important;
  border-bottom-width: 0.5px !important;
  border-bottom-color: rgba(0,0,0,0.38) !important;
}
.theme--light.v-text-field--outline > .v-input__control > .v-input__slot{
  border:  2px solid #7a7a7a !important;
}
.caption-new{
  font-size: 0.9rem;
  line-height: 1.0rem;
}
</style>
