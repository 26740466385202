<template>
  <v-dialog
    v-model="loginCUMA"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        outlined=""
        color=#f5333f
        dark>LINK CHULAMOOC
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          <span class="navy-text text-xs-center">Login with CHULA MOOC Achieve</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            class="mt-4"
            name="username"
            label="USERNAME"
            type="email"
            v-model="username"
            :rules="emailRules"
            required>
          </v-text-field>
          <v-text-field
            class="mt-4"
            name="password"
            label="PASSWORD"
            id="password" type="password"
            required v-model="password"
            :rules="passwordRules">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <a href="https://www.chulamoocachieve.com/signup" target="_blank">No account? Sign Up!!</a>
        <v-spacer></v-spacer>
        <v-btn
          color="#f5333f"
          outlined
          @click="loginCUMA = false"
        >
          CANCEL
        </v-btn>
        <v-btn
          color="#f5333f"
          @click="login"
        >
          <span class="white-text">LOG IN</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import memberServices from '../library/member';

const { errorMsg } = require('../static/errorMessage');

export default {
  name: 'LoginCUMA',
  data() {
    return {
      valid: false,
      loginCUMA: false,
      username: '',
      password: '',
      complete: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8
                    || 'Password must be greater than 8 characters',
      ],
    };
  },
  methods: {
    async login() {
      this.loginCUMA = false;
      if (this.$refs.form.validate()) {
        this.$store.dispatch('setLoading', true);
        const { username, password } = this;

        const dup = await memberServices.checkDuplicateEmail(username);
        if (!dup) {
          this.$store.dispatch('cumaLink', { username, password })
            .then((result) => {
              const token = result.access_token;
              this.$store.dispatch('cumaCheckEnroll', { token })
                .then(() => {
                  const { uid } = this.$store.getters.user;
                  this.$store.dispatch('getProfile', { uid })
                    .then(async () => {
                      const { cumaToken, isMember } = this.$store.getters.profile;
                      if (isMember) await memberServices.linkCumaData(cumaToken);
                      this.$store.dispatch('setLoading', false);
                    });
                });
              this.complete = token != null;
              this.emitToParent();
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            });
        } else {
          this.$store.dispatch('setError', errorMsg['cuma/linked']);
          this.$store.dispatch('setLoading', false);
        }
      }
    },
    emitToParent() {
      this.$emit('loginCumaComplete', this.complete);
    },
  },
};
</script>
